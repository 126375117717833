@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Jost", sans-serif;
}

@font-face {
  font-family: 'Actay';
  src: url(./fonts/actay/Actay-Regular.otf);
}

@font-face {
  font-family: 'ActayBold';
  src: url(./fonts/actay/ActayWide-Bold.otf);
}

.Mp_bg {
  background-color: #f7f1f1;
  width: 100%;
  height: 100vh;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainCSWrap {
  border-radius: 30px;
  background-color: white;
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url(./images/Group277132643.png);
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: auto;
  background-repeat: no-repeat;
}

.mainCSWrap .topElement {
  content: "";
  width: calc(100% - 100px);
  top: -30px;
  background-color: white;
  height: 80px;
  border-radius: 30px;
  position: absolute;
  background-image: url(./images/Group277132643.png);
  background-attachment: fixed;
}

.mainCSWrap .topBtnPlace {
  background-color: #f7f1f1;
  width: 200px;
  border-radius: 0px 0px 0px 30px;
  height: 60px;
  z-index: 3;
  color: black;
  /* position: absolute;
  top: -30px;
  right: -100px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0 0 10px 10px;
  right: 0;
  top: 0;
}

.mainCSWrap .topBtnPlace .topBtn {
  width: 100%;
  color: white;
  font-family: "Jost", sans-serif;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8C72F3;
  border-radius: 30px;
}

/* .mainCSWrap::before {
  content: "";
  width: 200px;
  height: 100%;
  position: absolute;
  right: -100px;
  top: 30px;
  border-radius: 30px;
  background-color: white;
  background-image: url(./images/Group277132643.png);
  background-attachment: fixed;
}

.mainCSWrap::after {
  content: "";
  width: 200px;
  height: 100%;
  position: absolute;
  left: -100px;
  top: -30px;
  border-radius: 30px;
  background-color: white;
  background-image: url(./images/Group277132643.png);
  background-attachment: fixed;
} */

.mainCSWrap .bottomBtnPlace {
  background-color: #f7f1f1;
  width: 200px;
  border-radius: 0px 30px 0px 0px;
  height: 60px;
  z-index: 3;
  color: black;
  /* position: absolute;
  bottom: -30px;
  left: -100px; */
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  justify-content: center;
  padding: 10px 10px 0 0;
}

.mainCSWrap .bottomBtnPlace .bottomBtn {
  width: 100%;
  color: #8C72F3;
  font-family: "Jost", sans-serif;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 30px;
  border: 1px #8C72F3 solid;
}

.bottomElement {
  content: "";
  width: calc(100% - 100px);
  bottom: -30px;
  background-color: white;
  height: 80px;
  border-radius: 30px;
  position: absolute;
  right: 0;
  background-image: url(./images/Group277132643.png);
  background-attachment: fixed;
}

.titles {
  z-index: 4;
  font-family: ActayBold, sans-serif;
  margin: 30px 0px 0px 30px;
}

.titles div {
  font-family: ActayBold, sans-serif;
}

.titles .title1 {
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
}

.titles .title2 {
  font-size: 35px;
}

.Mp_bg .content {
  z-index: 5;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  pointer-events: none;
}

.Mp_bg .content img {
  width: auto;
  height: 35vh;
  display: block;
}

.mainCSWrap .soc_icons {
  display: flex;
  align-items: center;
  gap: 30px;
  font-family: "Jost", sans-serif;
  margin: 0px 30px 30px auto;
}

.mainCSWrap .soc_icons img {
  height: 20px;
}

.mainCSWrap .soc_icons .soc_icon {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 16px;
  text-decoration: unset;
  border: 1px #8C72F3 solid;
}

.login_form .company_name {
  font-size: 25px;
  color: #8C72F3;
  text-align: center;
  font-weight: 700;
  margin-bottom: 31px;
}

.login_form img {
  margin: 0 auto;
  display: block;
}

.login_form input+input {
  margin-top: 10px;
}

.login_form input {
  height: 58px;
  font-size: 16px;
}

.login_form button {
  height: 58px;
  font-size: 18px;
}

.login_form .ant-modal-content {
  padding: 30px 60px;
}

.mainCSWrap .topContent,
.mainCSWrap .bottomContent {
  display: flex;
  justify-content: space-between;
}

.mainCSWrap .topContent{
  display: block;
    width: auto;
    padding-right: 210px;
}

.topBtnPlace::before {
  position: absolute;
  content: "";
  left: -20px;
  background: transparent;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0.313rem 0.313rem 0 0.313rem #f7f1f1;
  transform: rotate(270deg);
  top: 0;
}

.topBtnPlace::after {
  position: absolute;
  content: "";
  right: 0;
  background: transparent;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0.313rem 0.313rem 0 0.313rem #f7f1f1;
  transform: rotate(270deg);
  bottom: -20px;
}

.mainCSWrap .bottomContent {
  align-items: end;
}

.bottomBtnPlace::before {
  position: absolute;
  content: "";
  left: 0px;
  background: transparent;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0.313rem 0.313rem 0 0.313rem #f7f1f1;
  transform: rotate(90deg);
  top: -20px;
}

.bottomBtnPlace::after {
  position: absolute;
  content: "";
  right: -20px;
  background: transparent;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0.313rem 0.313rem 0 0.313rem #f7f1f1;
  transform: rotate(90deg);
  bottom: 0;
}

.wrapper {
  /* display: flex; */
  /* min-height: 100vh; */
}

.mainMenu {
  display: flex;
  border-radius: 0px 0px 16px 16px;
  gap: 20px;
  padding: 20px;
  width: 100%;
  background: #8c72f3;
  color: white;
  align-items: center;
}

.mainMenu a {
  color: white;
  text-decoration: unset;
}

.content {
  width: 100%;
}

.pageContent {
  padding: 20px;
}

/* .content.pad20 {
  padding: 20px;
} */

.page_name {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 20px;
}

.files_cards {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
}

.cardFileImg {
  width: 260px;
  height: 260px;
  display: block;
  background-size: auto !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.files_cards .ant-card-body {
  word-break: break-all;
}

.files_cards .ant-card-cover img {
  width: auto;
  margin: 0 auto;
  display: block;
  border-radius: 0;
  max-height: 260px;
  max-width: 100%;
}

.files_cards .ant-card-cover {
  max-height: 260px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.files_cards .ant-card {
  display: flex;
  flex-direction: column;
}

.files_cards .ant-card .ant-card-actions {
  margin-top: auto;
}

:root {
  --white: #fff;
  --box_shadow: 1px 0px 10px rgb(0 0 0 / 10%);
  --accent: #8C72F3;
}

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'Actay';
  src: url(./fonts/actay/Actay-Regular.otf);
}

@font-face {
  font-family: 'ActayBold';
  src: url(./fonts/actay/ActayWide-Bold.otf);
}


.ant-btn-primary {
  background-color: var(--accent);
}

.ant-menu-light .ant-menu-item-selected {
  color: white;
  background-color: var(--accent);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: Jost, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
.page_name,
.ant-drawer-title {
  font-family: ActayBold, sans-serif;
}


h1, h2, h3, h4, h5 {
  margin-bottom: 10px;
}

.vers {
  position: fixed;
  right: 0;
  bottom: 0;
}

@keyframes appearance {
  0% {
    transform: translatex(-100%);
  }

  100% {
    transform: translatex(0px);
  }
}

@keyframes appearanceBack {
  0% {
    transform: translatex(0px);
  }

  100% {
    transform: translatex(-100%);
  }
}

body {
  background-color: #f3f9ff;
}

.header {
  display: block;
  width: 300px;
  background-color: var(--white);
  height: 100vh;
  box-shadow: var(--box_shadow);
  padding: 20px;
  border-radius: 0px 30px 30px 0px;
}

.logo {
  width: 250px;
  display: block;
  margin: 0px auto;
}

.App {
  display: flex;
}

.wrapper {
  width: 100%;
  /* overflow: auto; */
}

.page_name {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 20px;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
  background-color: white;
}

.ant-layout {
  background-color: #F7F1F1;
}

.ant-layout-sider-trigger {
  background-color: white !important;
}

.ant-layout-sider-trigger span {
  color: #000;
}

.cards_place {
  display: block;
  gap: 20px;
}

.cards_place .ant-card {
  display: inline-block;
  margin: 5px;
}

.white_block {
  width: max-content;
  background: white;
  padding: 24px;
  border-radius: 8px;
  display: inline-block;
  margin: 5px;
}

.modal_field_name {
  margin-top: 10px;
  margin-bottom: 5px;
}

.login_page {
  max-width: 400px;
  padding: 20px;
  margin: 150px auto 0 auto;
  background-image: url("./images/login_bg.png");
  height: 600px;
  border-radius: 40px;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 500px;
  position: relative;
  background-blend-mode: color;
  background-color: #00000021;
}

.flex {
  display: flex;
  align-items: center;
}

.input_line_name {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
  padding: 4px 11px 4px;
}

.loadPlace {
  width: 100%;
  min-height: 200px;
  background: #ededed;
  border: #1677ff 1px dashed;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ant-card-cover {
  padding: 20px 20px 0px 20px;
}

.login_title_client {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.login_title_pers {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}

.login_container_client {
  transition: all .5s cubic-bezier(0.65, 0.05, 0.36, 1);
}


.login_container_pers {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  left: 0;
  border-radius: 38px;
  height: 80px;
  transition: all .5s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.diskItem {
  padding: 15px;
  display: inline-flex;
  width: 130px;
  text-align: center;
  margin: 5px;
  justify-content: center;
  cursor: pointer;
  height: 180px;
  border-radius: 10px;
}

.diskItem:hover {
  background-color: #e7e7e7;
}

.diskItemName {
  /* margin-top: 10px; */
  word-break: break-word;
}

.diskItemImage img {
  width: 100%;
}

.loading_screen {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: #ffffff8c;
}

.loading_screen img {
  position: absolute;
  width: 160px;
  top: 50%;
  left: 50%;
  margin-left: -80px;
  margin-top: -191.5px;
  -webkit-animation: rotating 1s cubic-bezier(0.88, 0.01, 0.24, 0.9) infinite;
  -moz-animation: rotating 1s cubic-bezier(0.88, 0.01, 0.24, 0.9) infinite;
  -ms-animation: rotating 1s cubic-bezier(0.88, 0.01, 0.24, 0.9) infinite;
  -o-animation: rotating 1s cubic-bezier(0.88, 0.01, 0.24, 0.9) infinite;
  animation: rotating 1s cubic-bezier(0.88, 0.01, 0.24, 0.9) infinite;
}


@-webkit-keyframes rotating

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.diskPageTitle {
  font-size: 30px;
  margin: 10px 0px;
}

.diskBreadCrumb {
  margin: 10px 0px;
  color: gray;
}

.diskBreadCrumbItem {
  cursor: pointer;
}

.react-contextmenu-item {
  padding: 5px 10px;
  background: white;
}

.react-contextmenu-item:hover {
  background: whitesmoke;
  cursor: pointer;
}

.react-contextmenu {
  border-radius: 10px;
  background: white;
  padding: 10px 0px;
}

/* .labelForFileInput {
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 300px;
  background: #eaeaea;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
} */

#upload-file {
  /* opacity: 0;
  position: absolute;
  z-index: -1; */
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 300px;
  background: #eaeaea;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.learning_page {
  width: 100%;
  height: calc(100vh - 5px);
  border: unset
}

.parsingFields .flex {
  display: flex;
  justify-content: space-between;
}

.parsingFields .flex+.flex {
  margin-top: 5px;
}

.custom_editor {
  padding: 20px;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
}

.DraftEditor-root {
  padding: 5px 20px;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
}


.lessons {
  padding: 10px;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
}

.lesson_item {
  padding: 10px;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  transition: all 0.3s ease;
  display: flex;
  gap: 10px;
}

.lesson_item:hover {
  box-shadow: 0px 0px 5px #b9b8b8;
}

.lesson_item+.lesson_item {
  margin-top: 10px;
}

.lesson_content {
  position: absolute;
  top: 0;
  padding: 30px;
  width: 100%;
  left: 0;
  height: 100vh;
  background: white;

  overflow: auto;
}

.courses {
  grid-gap: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.course_link {
  width: 100%;
  padding: 10px 10px;
  text-align: center;
  background-color: #8c72f3;
  color: white;
  display: block;
  max-width: 100%;
  border: 1px #8c72f3 solid;
  border-radius: 5px;
}

.course_link:hover {
  color: #8c72f3;
  background-color: white;
}

.course_name {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.course_desc {
  margin-bottom: 10px;
}

.lessons_grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  align-items: start;
}

.lessons_grid .lesson {
  padding: 20px;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  transition: all 0.3s ease;
  display: block;
}

.lessons_grid .lesson:hover {
  box-shadow: var(--box_shadow);
}

.module_name {
  padding: 10px 20px;
  background-color: #1677ff;
  color: white;
  border-radius: 16px;
  width: max-content;
  margin-bottom: 10px;
}

.homeWorks_grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin: 20px 0;
}

.homeWork_item+.homeWork_item {
  margin-top: 20px;
}

.course_img {
  display: block;
  width: 100%;
  height: 250px;
  border-radius: 16px;
  background-size: cover !important;
  margin-bottom: 15px;
  background-position: center !important;
}

.flex .course_img {
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  margin-bottom: 0;
}

.course_progresses_body {
  border-radius: 16px;
  gap: 20px;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
}

.course_progresses_body .flex {
  flex-wrap: wrap;
}

.course_lessons_body {
  padding: 20px;
  border-radius: 16px;
  background-color: white;
  margin-top: 20px;
}

.course_lessons_body h3 {
  margin-top: 0;
}

.teacher_course_place {
  width: max-content;
  background: #b6ec3d;
  padding: 12px 20px;
  border-radius: 16px;
  color: #242533;
  font-size: 18px;
  line-height: 1;
}

.teacher_course_place h4 {
  margin-top: 0;
  font-size: 14px;
  margin-bottom: 5px;
}

.big_course_name {
  font-size: 36px;
  font-family: ActayBold;
  font-weight: 700;
  margin-bottom: 15px;
}

.stat_bar {
  display: flex;
  gap: 12px;
  padding: 15px;
  align-items: center;
  width: max-content;
  background-color: white;
  border-radius: 16px;
  color: #000;
}

.stat_bar .cust_icon {
  width: 32px;
  height: 32px;
  display: block;
}

.stat_bar.progress_bar__icon .cust_icon {
  background: url(./images/icons/fire.webp);
}

.stat_bar.ads__icon .cust_icon {
  background: url(./images/icons/megaphone.webp);
}

.stat_bar.chat__icon .cust_icon {
  background: url(./images/icons/chat.webp);
}

.stat_bar.chat__icon {
  color: #000;
}

.stat_bar.start__icon .cust_icon {
  background: url(./images/icons/rocket.webp);
}

.module_block {
  padding: 15px;
  border-radius: 16px;
  background-color: white;
}

.module_block .name {
  font-size: 18px;
  font-weight: 700;
  font-family: ActayBold;
}

.lesson_list_item {
  display: grid;
  grid-template-columns: 27px 27px auto;
  align-items: center;
  gap: 10px;
  /* width: max-content; */
}

.lesson_list_item+.lesson_list_item {
  margin-top: 20px;
}

.lesson_list_item:first-child {
  margin-top: 20px;
}

.myLessonsVideos {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.myLessonsVideos iframe {
  border-radius: 16px;
  width: 100%;
  height: calc(auto - 30%);
}

.hw_letter_answer figure {
  margin: 0;
}

.files_cards {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
}

.cardFileImg {
  width: 260px;
  height: 260px;
  display: block;
  background-size: auto !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.files_cards .ant-card-body {
  word-break: break-all;
}

.files_cards .ant-card-cover img {
  width: auto;
  margin: 0 auto;
  display: block;
  border-radius: 0;
  max-height: 260px;
  max-width: 100%;
}

.files_cards .ant-card-cover {
  max-height: 260px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.files_cards .ant-card {
  display: flex;
  flex-direction: column;
}

.files_cards .ant-card .ant-card-actions {
  margin-top: auto;
}

.customModal {
  background-color: #00000030;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10000;
  height: 100vh;
  overflow-y: auto;
  padding: 30px;
}

.customModal__body {
  background-color: white;
  padding: 30px;
  border-radius: 16px;
  height: 100%;
  overflow-y: auto;
}

.customModal__header {
  margin-bottom: 10px;
}

.addHW {
  padding: 20px;
  margin-top: 20px;
  background: #eaeaea;
  border-radius: 16px;
}

.ant-notification,
.ant-tooltip,
.ant-popover {
  z-index: 10000000000 !important;
}

.addHW .DraftEditor-root {
  background: white;
}

.lesson_list_item_type svg {
  width: auto;
  height: 25px;
}

.lesson_list_item_type {
  margin: auto 0;
  display: flex;
}

.profile_courses {
  background: white;
  border-radius: 16px;
  padding: 20px;
  margin-top: 20px;
}

.profile_courses .courses {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}



.additionaMaterial_block{
  padding: 15px;
  border-radius: 16px;
  border: 1px #8C72F3 solid;
  width: max-content;
  display: block;
}

.questionName {
  font-family: 'ActayBold';
  margin-bottom: 10px;
}

.question + .question  {
  margin-top: 30px;
}

.digitalFootModal tr>th:first-child, .digitalFootModal tr>td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
}

.digitalFootModal .ant-modal-body {
  overflow-x: auto;
}

.digitalFootModal tr:nth-child(even) {
  background: #c0c0c036;
}

.digitalFootModal table {
  border-spacing: 0px;
}

.digitalFootModal tr td, .digitalFootModal tr th{
  padding: 2px;
}

.profile_short_data {
  display: flex;
  gap: 20px;
  width: '100%'; 
}

.client_avatar {
  width: 200px;
  height: 200px;
}

.material_body p {
  padding: 6px 0px;
  display: block;
  line-height: 1.3;
  font-family: "Jost", sans-serif !important;
}

.material_body ul {padding-left: 30px;}

.material_body * {  font-family: "Jost", sans-serif !important;}

@media screen and (max-width:  1920px) {
  .profile_courses .courses {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}


@media screen and (max-width:  1600px) {
  .profile_courses .courses {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lessons_grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width:  1280px) {
  .titles .title1 {
    font-size: 40px;
    line-height: 1.2;
  }

  .titles .title2 {
    font-size: 30px;
    line-height: 1;
  }

  .titles .title3 {
    line-height: 1.2;
  }

  
}


@media screen and (max-width:  1180px) {
  .profile_courses .courses {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .profile_courses {
    margin-top: 30px;
  }

  .lessons_grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width:  768px) {

  .lessons_grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .page_name, .big_course_name {
    font-size: 20px;
  }

  .profile_short_data {
    flex-direction: column;
  }  

  .mainCSWrap {
    height: calc(100% - 150px);
  }
  .profile_courses .courses {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .centerContent {
    margin: 20px 0px;
  }

  .login_form .ant-modal-content {
    padding: 20px;
  }

  .Mp_bg {
    padding: 15px;
    
  }

  .titles .title1 {
    font-size: 25px;
  }

  .titles .title2 {
    font-size: 20px;
  }

  .titles .title3 {
    margin-bottom: 35px;
  }

  .titles {
    margin: 30px 30px 10px 30px;
  }

  .mainCSWrap .topContent {
    display: block;
    width: auto;
    padding-right: 0px;
  }

  .mainCSWrap .soc_icons .soc_icon {
    font-size: 0;
    display: block;
  }
  
  .mainCSWrap .soc_icons {
    position: absolute;
    right: 10px;
    flex-direction: column;
    gap: 20px;
    margin: 0;
    top: calc(50% - 52px);
  }

  .mainCSWrap .bottomBtnPlace {
    position: relative;
  }
  
}

@media screen and (max-width:  534px) {
  .page_name {
    font-size: 18px;
  }

  .Mp_bg .content img {
    width: auto;
    height: 200px;
    display: block;
  }

  .titles .title3 {
    margin-bottom: 0;
    margin-top: 67px;
    font-size: 14px;
  }

  .titles .title1 {
    font-size: 18px;
  }

  .titles .title2 {
    font-size: 16px;
  }

  .mainMenu {
    flex-direction: column;
  }

  .teacher_course_place {
    width: 100%;
  }

}